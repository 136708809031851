<script setup lang="ts">
import { watch } from "vue";
import { useStorage } from "@vueuse/core";

const isDarkMode = useStorage("isDark", false);

watch(() => isDarkMode.value, injectThemeMode, {
  immediate: true,
});

function injectThemeMode() {
  document.body.setAttribute("data-theme", isDarkMode.value ? "dark" : "light");
  isDarkMode.value
    ? document.documentElement.classList.add("dark")
    : document.documentElement.classList.remove("dark");
}
</script>

<template>
  <div class="tj-theme-provider">
    <slot></slot>
  </div>
</template>
